

export const API_BASE_URL = 'https://api.ksrgroups.com';
// export const API_BASE_URL = 'http://localhost:8080';


export const API_ENDPOINTS = {
    // discovery
    ALL_DISCOVERYS: `${API_BASE_URL}/api/discoverys/allDiscoverys`,
    DISCOVERY: `${API_BASE_URL}/api/discoverys`,  // For , updating, deleting
   
    // abouts
    ALL_About: `${API_BASE_URL}/api/abouts/allAbouts`,
    About: `${API_BASE_URL}/api/abouts`,  // For creating, updating, deleting



};




























